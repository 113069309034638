import React from "react";
import General from "../../../utils/General";
import Image from "../../../utils/Image";

export default class SquareLogo extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      job: props.job,
      company: props.company
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState({job: nextProps.job, company: nextProps.company})
  }

  _renderLogo(){
    let {
      job,
      company
    } = this.state

    if(job){
      if(job.company.image_logo){
        return (
          <img
            src={Image.getImageUrl(job.company.image_logo, "job-logo")}
            className="logo img-rounded"
          />
        )
      }

      return (
        <div className="logo img-rounded" style={{backgroundColor: General.getRandomColorHex()}}>
          <div className="initials">
            {General.getInitials(job.company.name)}
          </div>
        </div>
      )
    }

    if(company.image_logo){
      return (
        <img
          src={Image.getImageUrl(company.image_logo, "other-options")}
          className="logo img-rounded"
          style={{borderRadius: 50}}
        />
      )
    }

    return (
      <div className="logo img-rounded" style={{backgroundColor: General.getRandomColorHex()}}>
        <div className="initials">
          {General.getInitials(company.name)}
        </div>
      </div>
    )
  }

  render() {
    let {
      job
    } = this.state

    return (
      <>
        { this._renderLogo() }
      </>
    )
  }
}
