import React from "react";
import renderHTML from 'react-render-html';
import JobDescriptionLogo from "./JobDescriptionLogo";
import {Link} from "react-router-dom";
import General from "../../../utils/General";
import Indeed from "../../../utils/Indeed";
import Backend from "../../../utils/Backend";
import moment from "moment";

export default class JobDescription extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      partner: props.partner,
      job: props.selectedJob,
      page: props.page,
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState({job: nextProps.selectedJob, partner: nextProps.partner})
  }

  _applyButton(){

    let {
      job,
      partner,
      page
    } = this.state

    let applyUrl = job.redirect ? Indeed.getIndeedTrackingLink(job.redirect, partner.slug) : `https://jobbio.com/talent/register?app_source=${partner.slug}&job=${job.id}&source=${partner.slug}`;

    if(job.jobkey){
      applyUrl = job.redirect
    }

    return(
      <>
        <div className="buttons">
          <a
            href={applyUrl}
            target={"_blank"}
            className="btn btn--md btn--primary type--uppercase"
            onClick={() => {
              Backend.applyClick('search_banner', partner)
              if(job.redirect){
                Backend.jobRedirect(job, partner, applyUrl)
              }
            }}
          >
            <span className="btn__text">Apply</span>
          </a>
        </div>
      </>
    )
  }

  render() {
    let {
      job,
      partner,
      page
    } = this.state

    if(job == null){
      return null
    }

    let jobLink = job.redirect ? Indeed.getIndeedTrackingLink(job.redirect, partner.slug) : window.General.JobPage.replace("[company]", job.company.slug)
      .replace("[job]", job.slug)
    let companyLink = window.General.CompanyJobsPage.replace("[company]", job.company.slug)
    let jobPage = window.General.JobPage.replace("[company]", job.company.slug).replace("[job]", job.slug)

    if(job.jobkey){
      jobLink = job.redirect
      companyLink = job.redirect
    }

    return (
      <>
        <div className="col-lg-12 search-job-detail">
          <div className="sidebar__inner">
            <div className="search-job-detail-container">

              <div className="info">
                <JobDescriptionLogo
                  job={job}
                  partner={partner}
                />

                <div className="j-details">
                  <a href={jobPage} className="job-name">{job.title}</a>
                  <div className="detail-c-name">{job.company.name}</div>
                  <div className="location">{job.location.city || job.location.state || job.location.country}</div>
                  <div className="salary">
                    {
                      job.salary_disclosed &&
                      <span className="amount">
                        {job.salary}
                      </span>
                    }
                    {
                      job.contract !== '-' &&
                      <span className="contract">
                        {General.dashCaseToTitleCase(job.contract)}
                      </span>
                    }
                  </div>
                  <div className="posted">Posted {moment(job.published_at).fromNow()}</div>
                </div>

                <div className="ml-5 mt-3 justify-content-center">
                  {this._applyButton()}
                </div>
              </div>

              <div className="row boxed boxed--border post post-text">
                {renderHTML(job.description)}
              </div>

              <div className="btm-button">
                {this._applyButton()}
              </div>

            </div>
          </div>
        </div>
      </>
    )
  }
}
