import React from "react";

export default class OnBoardingBanner extends React.Component{

  render() {
    return (
      <>
        <div className="on-boarding-banner">
          <div className="container">
            <div className="row align-items-center">
              <div className="col text-center">
                <strong>Welcome to Stack Overflow Jobs powered by Indeed.</strong> A dedicated space for developers and technologists to find their next job. <a href="https://stackoverflow.co/labs/stack-overflow-jobs-powered-by-indeed?source=banner&utm_source=banner&utm_medium=partner_referral&utm_campaign=stackoverflowjobs-indeed&utm_content=about-job-board" target="_blank">Learn more about this job site</a>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
