import React from "react";
import {Link} from "react-router-dom";

const SOCIAL_LIST = [
  {
    href: "https://twitter.com/StackOverflow?utm_source=stackoverflowjobs-indeed&utm_medium=partnership&utm_campaign=job-board-footer&utm_content=twitter-icon",
    css: "small",
    svg: <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50" width="36px" height="36px"><path d="M 6.9199219 6 L 21.136719 26.726562 L 6.2285156 44 L 9.40625 44 L 22.544922 28.777344 L 32.986328 44 L 43 44 L 28.123047 22.3125 L 42.203125 6 L 39.027344 6 L 26.716797 20.261719 L 16.933594 6 L 6.9199219 6 z"/></svg>
  },
  {
    href: "https://www.threads.net/@thestackoverflow?utm_source=stackoverflowjobs-indeed&utm_medium=partnership&utm_campaign=job-board-footer&utm_content=threads-icon",
    css: "small",
    svg: <svg xmlns="http://www.w3.org/2000/svg" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 439.999 511.429"><path fill-rule="nonzero" d="M342.382 237.037a175.843 175.843 0 00-6.707-3.045c-3.947-72.737-43.692-114.379-110.428-114.805-38.505-.256-72.972 15.445-94.454 48.041l36.702 25.178c15.265-23.159 39.221-28.097 56.864-28.097.203 0 .408 0 .61.003 21.973.139 38.555 6.528 49.287 18.987 7.81 9.071 13.034 21.606 15.62 37.425-19.482-3.312-40.552-4.329-63.077-3.039-63.449 3.656-104.24 40.661-101.5 92.081 1.39 26.083 14.384 48.522 36.586 63.18 18.773 12.391 42.95 18.451 68.078 17.08 33.183-1.819 59.214-14.48 77.376-37.631 13.793-17.579 22.516-40.362 26.368-69.068 15.814 9.543 27.535 22.103 34.007 37.2 11.007 25.665 11.648 67.84-22.764 102.222-30.15 30.121-66.392 43.151-121.164 43.554-60.757-.45-106.707-19.934-136.582-57.914-27.976-35.563-42.434-86.93-42.973-152.675.539-65.745 14.997-117.113 42.973-152.675 29.875-37.979 75.824-57.464 136.581-57.914 61.197.455 107.948 20.033 138.967 58.195 15.21 18.713 26.677 42.248 34.236 69.688l43.011-11.476c-9.163-33.775-23.581-62.881-43.203-87.017C357.031 25.59 298.872.519 223.935 0h-.3C148.851.518 91.343 25.683 52.709 74.794 18.331 118.498.598 179.308.002 255.534l-.002.18.002.18c.596 76.226 18.329 137.037 52.707 180.741 38.634 49.11 96.142 74.277 170.926 74.794h.3c66.487-.462 113.352-17.868 151.96-56.442 50.511-50.463 48.991-113.717 32.342-152.548-11.944-27.847-34.716-50.464-65.855-65.402zm-114.795 107.93c-27.809 1.566-56.7-10.917-58.124-37.652-1.056-19.823 14.108-41.942 59.83-44.577 5.237-.302 10.375-.45 15.422-.45 16.609 0 32.146 1.613 46.272 4.702-5.268 65.798-36.173 76.483-63.4 77.977z"/></svg>
  },
  {
    href: "https://www.linkedin.com/company/stack-overflow/?utm_source=stackoverflowjobs-indeed&utm_medium=partnership&utm_campaign=job-board-footer&utm_content=linkedin-icon",
    css: "",
    svg: <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 30 30" width="36px" height="36px">    <path d="M9,25H4V10h5V25z M6.501,8C5.118,8,4,6.879,4,5.499S5.12,3,6.501,3C7.879,3,9,4.121,9,5.499C9,6.879,7.879,8,6.501,8z M27,25h-4.807v-7.3c0-1.741-0.033-3.98-2.499-3.98c-2.503,0-2.888,1.896-2.888,3.854V25H12V9.989h4.614v2.051h0.065 c0.642-1.18,2.211-2.424,4.551-2.424c4.87,0,5.77,3.109,5.77,7.151C27,16.767,27,25,27,25z"/></svg>
  },
  {
    href: "https://www.youtube.com/c/StackOverflowOfficial?utm_source=stackoverflowjobs-indeed&utm_medium=partnership&utm_campaign=job-board-footer&utm_content=youtube-icon",
    css: "",
    svg: <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" width="36px" height="36px">    <path d="M21.582,6.186c-0.23-0.86-0.908-1.538-1.768-1.768C18.254,4,12,4,12,4S5.746,4,4.186,4.418 c-0.86,0.23-1.538,0.908-1.768,1.768C2,7.746,2,12,2,12s0,4.254,0.418,5.814c0.23,0.86,0.908,1.538,1.768,1.768 C5.746,20,12,20,12,20s6.254,0,7.814-0.418c0.861-0.23,1.538-0.908,1.768-1.768C22,16.254,22,12,22,12S22,7.746,21.582,6.186z M10,14.598V9.402c0-0.385,0.417-0.625,0.75-0.433l4.5,2.598c0.333,0.192,0.333,0.674,0,0.866l-4.5,2.598 C10.417,15.224,10,14.983,10,14.598z"/></svg>
  },
]

export default class Footer extends React.Component{

  constructor(props) {
    super(props);

    this.state = {
      partner: props.partner
    }
  }

  render() {
    let {
      partner
    } = this.state

    return (
      <>
        <footer id="footer" className="footer space--sm footer-3 bg--primary">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <span className="type--fine-print">© <span className="update-year">2022</span> Jobbio</span>
                {
                  partner.settings.privacy_policy_url !== "" &&
                  <a href={partner.settings.privacy_policy_url} target={"_blank"} className="type--fine-print">Privacy Policy</a>
                }
                {
                  partner.settings.cookie_policy_url !== "" &&
                  <a href={partner.settings.cookie_policy_url} target={"_blank"} className="type--fine-print">Cookie Policy</a>
                }
                {
                  partner.settings.use_policy_url !== "" &&
                  <a href={partner.settings.use_policy_url} target={"_blank"} className="type--fine-print">Use Policy</a>
                }
                {
                  partner.settings.terms_conditions_url !== "" &&
                  <a href={partner.settings.terms_conditions_url} target={"_blank"} className="type--fine-print">Terms & Conditions</a>
                }
              </div>
              <div className="col-md-6 text-right text-left-xs">
                <ul className="social-list list-inline list--hover">
                  {
                    SOCIAL_LIST.map(option => {
                      return (
                        <li className={option.css}>
                          <a href={option.href}>
                            {option.svg}
                          </a>
                        </li>
                      )
                    })
                  }
                </ul>
              </div>
            </div>
            <div className="row mt-5 pb-5">
              <div className="col-md-12">
                <p className="type--fine-print">
                  Stack Overflow Jobs, powered by Indeed, is hosted by Jobbio. For more details on how Jobbio uses cookies and protects your personal data, please review their Cookie Policy and Privacy Policy.
                </p>
              </div>
            </div>
          </div>
        </footer>
      </>
    )
  }
}
