import FetchHelper from './FetchHelper'
import AuthManager from './AuthManager'

export default class Backend {

  static getPageData(domain, mode, companySlug, jobSlug){
    let visitorId = localStorage.getItem("visitorId");
    if(mode === "job"){
      return FetchHelper.get(`${window.Api.Channels}/data?domain=${domain}&source=${domain}_${mode}&job_slug=${jobSlug}&company_slug=${companySlug}${visitorId ? `&visitor_id=${visitorId}` : ''}`)
    }
    return FetchHelper.get(`${window.Api.Channels}/data?domain=${domain}&source=${domain}_${mode}${visitorId ? `&visitor_id=${visitorId}` : ''}`)
  }

  static getPartnerCompanies(partner, exclude = false){
    return FetchHelper.get(`${window.Api.Channels}/${partner.slug}/companies?source=${partner.slug}${exclude ? '&featured=false' : ''}&page_size=${partner.settings.company_no}`)
  }

  static getItemsSectionResults(type, partner){
    if(type === "channels"){
      return FetchHelper.get(`${window.Api.Channels}/${partner}/channels?page_size=6&source=${partner}`)
    }
    return FetchHelper.get(`${window.Api.Articles}?page_size=6&channel=${partner}&source=${partner}`)
  }

  static getChannelFeed(channel, partner, searchTerm, pageSize=5){
    return FetchHelper.get(`${window.Api.Channels}/${channel}/feed?page_size=${pageSize}&source=${partner}&search=${searchTerm || ''}`)
  }

  static getJobs(filter){
    let endpoint = window.Api.Jobs;
    if(filter){
      endpoint = endpoint + filter
    }
    return FetchHelper.get(`${endpoint}`)
  }

  static getChannel(channelSlug, partner){
    return FetchHelper.get(`${window.Api.Channels}/${channelSlug}?source=${partner}`)
  }

  static getBlocks(companySlug){
    return FetchHelper.get(`${window.Api.Blocks}?company_slug=${companySlug}`)
  }

  static getJob(jobSlug, companySlug, partner){
    return FetchHelper.get(`${window.Api.Jobs}/${companySlug}/${jobSlug}?source=${partner}`)
  }

  static getOtherJobs(companySlug, partner){
    return FetchHelper.get(`${window.Api.Companies}/${companySlug}/jobs?page_size=5&source=${partner}`)
  }

  static jobRedirect(job, partner, link){
    let visitorId = localStorage.getItem("visitorId");
    let data = {
      job_id: job.id,
      source: partner.slug,
      link: link
    }
    return FetchHelper.post(`${window.Api.Redirects}${visitorId ? `?visitor_id=${visitorId}` : ''}`, data)
  }

  static jobView(job, partner){
    let data = {
      job: job.id,
      source: partner.slug
    }
    return FetchHelper.post(`${window.Api.View}`, data)
  }

  static applyClick(type, partner){
    let data = {
      type,
      source: partner.slug
    }
    return FetchHelper.post(`${window.Api.Clicks}`, data)
  }

  static getFilters(channel){
    return FetchHelper.get(`${window.Api.Channels}/${channel}/feed/filters`)
  }

  static uploadFile(file, endpoint, params){
    let data = new FormData()

    data.append("file", file, file.name)
    for(var key in params){
      data.append(key, params[key])
    }

    return FetchHelper.post(endpoint, data, true)
  }

  static uploadImage(image, type){

    let data = new FormData()

    data.append("file", image, image.name)
    data.append("type", type)

    return FetchHelper.post(window.Api.Images, data, true)
  }

  static getArticles(filter){
    let endpoint = window.Api.Articles;
    if(filter){
      endpoint = endpoint + filter
    }
    return FetchHelper.get(`${endpoint}`)
  }

  static getArticle(articleSlug, partner){
    return FetchHelper.get(`${window.Api.Articles}/${articleSlug}?source=${partner}`)
  }

  static getSimilarArticles(article, partner){
    return FetchHelper.get(`${window.Api.Articles}?page_size=3&article_id=${article.id}&source=${partner}&channel=${partner}`)
  }

  static getArticleCategories(partner){
    return FetchHelper.get(`${window.Api.Categories}?channel=${partner}&paginated=false`)
  }

  static registerSearch(searchTerm, partner){
    let data = {search: searchTerm, source: partner.slug}
    FetchHelper.post(window.Api.Search, data)
  }

  static logTraffic(data){
    let visitorId = localStorage.getItem("visitorId");
    return FetchHelper.post(`${window.Api.Traffic}${visitorId ? `?visitor_id=${visitorId}` : ''}`, data)
  }

  static adBlocker(){
    return FetchHelper.get("https://www3.doubleclick.net")
  }

}
