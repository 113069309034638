
export default class General {
  static uuid(){
    var dt = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = (dt + Math.random()*16)%16 | 0;
      dt = Math.floor(dt/16);
      return (c=='x' ? r :(r&0x3|0x8)).toString(16);
    });
    return uuid;
  }

  static updateImageBackgrounds(){
    if(!window.mr){
      console.warn("mr backgrounds not initiated")
      return
    }
    window.mr.backgrounds.documentReady(window.$)
  }

  static updateGradients(){
    window.$("canvas[id*='granim']").remove()
    if(!window.mr){
      console.warn("mr granim not initiated")
      return
    }
    window.mr.granim.documentReady(window.$)
  }

  static updateParallax(){
    if(!window.mr){
      console.warn("mr parallax not initiated")
      return
    }
    window.mr.parallax.update()
  }

  static updateSlides(){
    if(!window.mr){
      console.warn("mr sliders not initiated")
      return
    }
    window.mr.sliders.documentReady(window.$)
  }

  static updateTabs(){
    if(!window.mr){
      console.warn("mr tabs not initiated")
      return
    }
    window.mr.tabs.documentReady(window.$)
  }

  static updateVideo(){
    if(!window.mr){
      console.warn("mr video not initiated")
      return
    }
    window.mr.video.documentReady(window.$)
  }

  static updateTwitter(){
    if(!window.mr){
      console.warn("mr twitter not initiated")
      return
    }
    window.mr.twitter.documentReady(window.$)
  }

  static updateMasonry(){
    if(!window.mr){
      console.warn("mr masonry not initiated")
      return
    }
    window.mr.masonry.documentReady(window.$)
  }

  static updateModals(){
    if(!window.mr){
      console.warn("mr modal not initiated")
      return
    }
    window.mr.modals.documentReady(window.$)
  }

  static updateNav(element){
    if(!window.mr){
      console.warn("mr bars not initiated")
      return
    }

    window.mr.scroll.classModifiers.parseScrollRules(element)
  }

  static updateSmoothScroll(){
    if(!window.mr){
      console.warn("mr smooth scroll not initiated")
      return
    }
    window.mr.smoothscroll.documentReady(window.$)
  }

  static updateDataToggle(){
    if(!window.mr){
      console.warn("mr data toggle not initiated")
      return
    }
    window.mr.toggleClass.documentReady(window.$)
  }

  static updateAll(){
    General.updateSlides()
    General.updateParallax()
    General.updateGradients()
    General.updateImageBackgrounds()
    General.updateTabs()
    General.updateTwitter()
    General.updateMasonry()
    General.updateModals()
    General.updateSmoothScroll()
    General.updateDataToggle()

    // TODO: proper fix for this, when synchronous the video
    // does not play
    setTimeout(() => {
      General.updateVideo()
    }, 500)
  }

  static debounce(func, wait, immediate) {
  	var timeout;
  	return function() {
  		var context = this, args = arguments;
  		var later = function() {
  			timeout = null;
  			if (!immediate) func.apply(context, args);
  		};
  		var callNow = immediate && !timeout;
  		clearTimeout(timeout);
  		timeout = setTimeout(later, wait);
  		if (callNow) func.apply(context, args);
  	};
  };

  static dashCaseToTitleCase(str){
    let words = str.replace('-',' ')
    return General.toTitleCase(words)
  }

  static snakeCaseToTitleCase(str){
    let words = str.replace('_',' ')
    return General.toTitleCase(words)
  }

  static toTitleCase(str) {
    return str.replace(/\w\S*/g, function(txt){
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  static getInitials(str){
    var initials = str.match(/\b\w/g) || [];
    initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
    return initials
  }

  static getRandomColorState(){
    let colors = ["info", "success", "warning", "danger", "dark", "primary", "brand"]
    let color = colors[Math.floor(Math.random()*colors.length)];
    return color
  }

  static getCurrency(currency){
    switch (currency) {
      case 'eur':
        return '€';
      case 'gbp':
        return '£';
      case 'usd':
      case 'cad':
        return '$';
      default:
        return '€';
    }
  }

  static getUrlParameter(name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(window.location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
  }

  static getRandomColorHex(){
    let colors = ['57423F','BFA6A2','0098FF','00AF69','9B72FF'];
    let color = '#'+colors[Math.floor(Math.random()*colors.length)];
    return color
  }

  static getRandomBannerImage(){
    let max = 4;
    let min = 1;
    let number = Math.floor(Math.random() * (max - min + 1)) + min;
    let imageUrl = `${window.General.CloudFrontResizer}images/site/company-placeholders/company-banner-${number}.jpeg`;
    return imageUrl;
  }

  static getIndeedTrackingLink(url, partner){
    if(url.includes('pubnum')){
      return url
    }
    if(url.includes('indeed.')){
      if(url.includes('?')){
        url = `${url}&sid=jobbio_${partner}&kw=jobbio_${partner}`
      }else{
        url = `${url}?sid=jobbio_${partner}&kw=jobbio_${partner}`
      }
      var si = url.indexOf('&jsa=');
      if (si > 0) return url;
      var jsh = `${url}&jsa=0000`;
      if (jsh.indexOf('&inchal') == -1) jsh += '&inchal=apiresults';
      return jsh
    }else{
      return url
    }
  }

  static getTrafficData(partner){
    return {
      destination: partner.slug,
      source: General.getUrlParameter("source"),
      referrer_url: document.referrer
    }
  }
}
