import React from "react";
import {Link} from "react-router-dom";
import CookiePolicyPopUp from "./CookiePolicyPopUp";
import OnBoardingBanner from "./OnBoardingBanner";
import $ from 'jquery';

export default class NavigationBar extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      opacity: props.opacity,
      partner: props.partner,
      headerFixed:props.headerFixed

    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  componentDidMount() {

    // starts : header fixed
    $(window).scroll(function() {
      var scroll = $(window).scrollTop();
      if (scroll > 366) {
        $('.header-fixed nav#menu1').addClass('pos-fixed');
        $('.header-fixed').addClass('h-pos-fixed');
      } else {
        $('.header-fixed nav#menu1').removeClass('pos-fixed');
        $('.header-fixed').removeClass('h-pos-fixed');
      }
    });
    // end : header fixed

  }

  _renderMenuLinks(){
    let {
      partner
    } = this.state

    return partner.info.map(item => {
      if(item.type === "content"){
        return (
          <li>
            <a
              data-scroll
              href={"#info-tabs"}
              onClick={() => {
                window.mr.tabs.activateTab(`#list${item.id}`)
              }}
            >
              {item.title}
            </a>
          </li>
        )
      }
    })
  }

  render() {
    let {
      partner,
      headerFixed
    } = this.state

    let homeLink = window.General.HomePage
    let jobsLink = window.General.JobsPage
    let companiesLink = window.General.CompaniesPage
    let articlesLink = window.General.ArticlesPage

    let parentClass = "nav-container"
    if (headerFixed === "true"){
      parentClass += " header-fixed"
    }

    return (
      <>
        <OnBoardingBanner />
        <div id="nav-container" className={parentClass}>
          <div>

            <div className="bar bar--sm visible-xs">
              <div className="container">
                <div className="row">
                  <div className="col-3 col-md-2">
                    <a href={partner.settings.menu_logo_url}>
                      <img className="logo logo-dark" alt="logo" src={partner.settings.menu_secondary_logo || partner.settings.menu_logo} />
                      <img className="logo logo-light" alt="logo" src={partner.settings.menu_logo} />
                    </a>
                  </div>
                  <div className="col-9 col-md-10 text-right">
                    <a href="#" className="hamburger-toggle" data-toggle-class="#menu1;hidden-xs hidden-sm">
                      <i className="icon icon--sm stack-interface stack-menu"></i> </a>
                  </div>
                </div>
              </div>
            </div>

            <nav id="menu1" className={`bar bar-1 hidden-xs ${this.state.opacity || ''}` }>
              <div className="container">
                <div className="row justify-content-between align-items-center">
                  <div className="col-auto hidden-xs">
                    <div className="bar__module">
                      <a href={partner.settings.menu_logo_url}>
                        <img className="logo logo-dark" alt="logo" src={partner.settings.menu_secondary_logo || partner.settings.menu_logo} />
                        <img className="logo logo-light" alt="logo" src={partner.settings.menu_logo} />
                      </a>
                    </div>
                  </div>
                  <div className="col-sm col-lg-auto text-right text-left-xs text-left-sm">
                    <div className="bar__module">
                      <ul className="menu-horizontal text-left">
                        <li>
                          <a href="https://stackoverflow.co/labs/stack-overflow-jobs-powered-by-indeed?source=navbar&utm_source=navbar&utm_medium=partner_referral&utm_campaign=stackoverflowjobs-indeed&utm_content=about-job-board" target="_blank">
                            About Job Site
                          </a>
                        </li>
                        <li>
                          <a href="https://stackoverflow.com?source=navbar&utm_source=navbar&utm_medium=partner_referral&utm_campaign=stackoverflowjobs-indeed&utm_content=back-to-so" target="_blank">
                            Back to Stack Overflow
                          </a>
                        </li>
                        <li className="d-line">&nbsp;</li>
                        <li>
                          <a href={partner.settings.menu_job_url} target="_blank">
                            {partner.settings.menu_job_cta}
                          </a>
                        </li>
                        <li>
                          <a href="https://www.indeed.com/hire?ref=jobbio_stackoverflow_post_job" target="_blank">
                            Employers / Post Job
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </nav>

          </div>
        </div>
      </>
    )
  }
}

NavigationBar.defaultProps = {
  showMenu: false
}
