import React from 'react';
import './App.css';

import Api from './constants/Api'
import General from './constants/General'

import GeneralUtil from './utils/General'
import Indeed from './utils/Indeed'

import './assets/css/stack-interface.css'
import './assets/css/socicon.css'
import './assets/css/iconsmind.css'
import './assets/css/bootstrap.css'
import './assets/css/theme-red.css'
import './assets/css/custom.css'
import './assets/css/font-awesome.min.css'
import './assets/css/flickity.css'
import './assets/scss/app.scss'

import { BrowserRouter, Switch } from 'react-router-dom';
import Route from './components/UnauthenticatedRoute';

import { Helmet } from 'react-helmet';

import SearchJobs from './pages/SearchJobs'
import Job from "./pages/Job";

import Backend from "./utils/Backend";
import PartnerBranding from "./pages/components/common/PartnerBranding";
import PreLoad from "./pages/components/common/PreLoad";

import TagManager from 'react-gtm-module'

export default class App extends React.Component {

  constructor(props){
    super(props)

    this.state = {
      partner: null,
      source: GeneralUtil.getUrlParameter("source"),
    }
  }

  componentDidMount() {
    this._setup()
  }

  async _setup(){
    let domain = window.location.host

    if(domain.includes("localhost")){
      domain = "so.jobbiodev.com"
    }

    let pathname = window.location.pathname

    let companySlug = null
    let jobSlug = null

    let indexMode = false
    let jobMode = false
    let mode = "index"

    if(pathname !== null){
      pathname = pathname.substring(1, window.location.pathname.length).split("/")

      jobMode = pathname.includes("job")

      if(!jobMode){
        indexMode = true
      }else if(jobMode){
        pathname = pathname[pathname.indexOf("job")+1].split("-at-")
        companySlug = pathname[1]
        jobSlug = pathname[0]
        mode = "job"
      }
    }

    let pageData = null
    try {
      pageData = await Backend.getPageData(domain, mode, companySlug, jobSlug)

      if(pageData.visitor_id){
        localStorage.setItem("visitorId", pageData.visitor_id)
      }

      let partner = pageData.partner_data

      window.General.IndeedImpressionTracking = partner.settings.indeed_impression_tracking

      this._setTracking(partner)

      if(!window.location.host.includes("localhost")) {
        window.General.HomePage = partner.routes.home_url
        window.General.ChannelPage = partner.routes.channel_url
        window.General.CompanyPage = partner.routes.company_url
        window.General.CompanyJobsPage = partner.routes.company_jobs_url
        window.General.JobPage = partner.routes.job_url
        window.General.ArticlePage = partner.routes.article_url
        window.General.CompaniesPage = partner.routes.companies_url
        window.General.JobsPage = partner.routes.jobs_url
        window.General.ArticlesPage = partner.routes.articles_url
      }
      window.Api.GoogleMapsKey = partner.settings.gmaps_key
    }catch (e){
      console.log(e)
    }


    this.setState({
      indexMode,
      jobMode,
      companySlug,
      jobSlug,
      pageData
    })
  }

  _setTracking(partner){

    let gtmId = partner.settings.gtm_id

    if(!gtmId){
      return
    }

    const tagManagerArgs = {
      gtmId: gtmId
    }

    TagManager.initialize(tagManagerArgs)
  }

  _renderContent(){
    let {
      indexMode,
      jobMode,
      companySlug,
      jobSlug,
      pageData,
      source
    } = this.state

    let partner = pageData.partner_data

    if(indexMode){
      return <SearchJobs partner={partner} />
    }

    if(jobMode){
      return <Job partner={partner} job={pageData.job_data} jobs={pageData.similar_jobs_data.results} companySlug={companySlug} jobSlug={jobSlug} source={`${source}`} />
    }

  }

  render() {
    let {
      pageData,
    } = this.state

    if(!pageData) return <PreLoad/>

    let partner = pageData.partner_data

    let favicon = partner.settings?.favicon || partner.channel_data?.settings.favicon || 'https://partner.jobbio.com/favicon.ico'

    let index = true
    if(!partner.settings.allow_index){
      index = false
    }

    return (
      <>
        <Helmet>
          <link rel={"icon"} href={favicon} />
          {
            !index && <meta name="robots" content="noindex"/>
          }
        </Helmet>
        <PartnerBranding partner={partner} />
        {this._renderContent()}
      </>
    )
  }
}
