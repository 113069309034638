import runtimeEnv from '@mars/heroku-js-runtime-env';

const env = runtimeEnv();

window.General = {};

window.General.CloudFront = env.REACT_APP_AWS_CLOUD_FRONT
window.General.CloudFrontResizer = env.REACT_APP_AWS_CLOUD_FRONT_RESIZER

window.General.IndeedImpressionTracking = false

window.General.JobbioSite = env.REACT_APP_SITE

window.General.HomePage = 'http://localhost:3004'
window.General.ChannelPage ='http://localhost:3003/channel/[channel]/'
window.General.CompanyPage ='http://localhost:3003/company/[company]/'
window.General.CompanyJobsPage ='http://localhost:3003/company/[company]/jobs'
window.General.JobPage ='http://localhost:3004/job/[job]-at-[company]'
window.General.ArticlePage ='http://localhost:3003/article/[article]/'
window.General.CompaniesPage ='http://localhost:3003/search/companies/'
window.General.JobsPage ='http://localhost:3003/search/jobs/'
window.General.ArticlesPage ='http://localhost:3003/search/articles/'
